class Vars {
    HOST_ENV: string = import.meta.env.VITE_HOST;
    HOST_UPLOADS: string = import.meta.env.VITE_HOST_UPLOADS;
    HOST_API: string = import.meta.env.VITE_HOST_API;

    dateFormat = 'YYYY-MM-DD';
    dateTimeFormat = 'YYYY-MM-DD HH:mm';

    pagination = { page: 1, pages: 1, previous: 0, next: 0, count: 1 };

    userGroups: { [key: string]: string } = {
        admin: 'Администратор',
        price: 'Прайс-менеджер',
        lawyer: 'Юрист ОП',
        lawyerleader: 'Старший юрист ОП',
        leader: 'Руководитель офиса продаж',
        agent: 'Менеджер офиса продаж',
        developer: 'Застройщик',
    };

    validateMessagesForm = {
        required: 'Поле не может быть пустым',
        types: {
            email: 'Неправильный формат email!',
            number: 'Значение должно быть числом',
            url: 'Неправильный формат URL',
        },
        string: {
            len: 'Длина должна быть ${len}',
            max: 'Максимальная длина ${max} символов',
            min: 'Минимальная длина ${min} символов',
            range: 'Длина должна быть от ${min} до ${max} символов',
        },
        number: {
            min: 'Значение не может быть меньше ${min}',
            max: 'Значение не может быть больше ${max}',
        },
        pattern: {
            mismatch: 'Значение не соответствует требуемому шаблону',
        },
    };

    reMail: RegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    rePhone: RegExp = /^\+?\d{1}?\s*?\(?\d{3}(?:\)|[-|\s])?\s*?\d{3}[-|\s]?\d{2}[-|\s]?\d{2}$/;
}

const vars = new Vars();
export default Object.freeze(vars);
